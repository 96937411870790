import { Component, Input } from '@angular/core';
import { SharedModule } from '../shared.module';

@Component({
  selector: 'vw-icon-svg', // Selector for the icon SVG component
  templateUrl: './icon-svg.component.html', // Template file for the icon SVG component
  styleUrls: ['./icon-svg.component.scss'] // Styles for the icon SVG component
})
export class IconSvgComponent {
  @Input() iconUrl: string = ''; // Input property for the URL of the SVG icon
  @Input() color: string = 'currentColor'; // Input property for the icon color, defaulting to 'currentColor'
  @Input() width: string = '8px'; // Input property for the icon width
  @Input() height: string = '8px'; // Input property for the icon height
  @Input() additionalClasses: string = ''; // Input property for any additional CSS classes to apply
}
