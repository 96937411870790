<!-- Modal Backdrop -->
<div *ngIf="isOpen" class="fixed inset-0 bg-gray-800 backdrop-blur-sm bg-opacity-20 flex justify-center items-center z-[9999]">
    <!-- Modal Container -->
    <div [ngStyle]="{ width: width, height: height }"
         [ngClass]="customClasses"
         class="bg-white rounded-lg shadow-lg relative p-4 pb-6 z-[99999] w-11/12">

      <!-- Optional Close Icon (using content projection for custom SVG) -->
      <button *ngIf="showCloseIcon" (click)="closeModal()"
              class="absolute top-2 right-2 text-gray-600 hover:text-gray-800">
        <ng-content select="[closeIcon]"></ng-content> <!-- Customizable SVG icon -->
      </button>

      <!-- Modal Header (content projection) -->
      <div class="pb-2 text-center">
        <ng-content select="[modalHeader]"></ng-content>
      </div>

      <!-- Modal Body (content projection) -->
      <div class="p-0">
        <ng-content select="[modalBody]"></ng-content>
      </div>

      <!-- Modal Footer (content projection) -->
      <div class="pt-0">
        <ng-content select="[modalFooter]"></ng-content>
      </div>
    </div>
  </div>
