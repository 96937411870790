<div [ngClass]="customClasses">
  <!-- Dual range slider (min-max) -->
  <ngx-slider *ngIf="isRange"
    [(value)]="minValue"
    [(highValue)]="maxValue"
    [options]="{
      floor: floor,
      ceil: ceil,
      translate: options.translate
    }"
    (valueChange)="onSliderChange()"
    class="two-side-range"
  ></ngx-slider>

  <!-- Single value slider -->
  <ngx-slider *ngIf="!isRange"
    [(value)]="value"
    class="two-side-range one-range"
    [options]="{
      floor: floor,
      ceil: ceil,
      getSelectionBarColor: options.getSelectionBarColor,
      translate: options.translate
    }"
    (valueChange)="onSliderChange()"
  ></ngx-slider>
</div>
