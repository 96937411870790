import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vw-button', // Selector for the button component
  templateUrl: './button.component.html', // Template file for the button component
  styleUrls: ['./button.component.scss'] // Corrected to 'styleUrls' for consistency with Angular documentation
})
export class ButtonComponent {
  @Input() isDisabled: boolean = false;
  @Input() label: string = 'Button'; // Button label text
  @Input() type: 'button' | 'submit' | 'reset' = 'button'; // Button type attribute
  @Input() color: string = 'bg-primary hover:bg-white'; // Background color classes for the button
  @Input() textColor: string = 'text-white'; // Text color classes for the button
  @Input() icon: string = ''; // Optional icon class or name to be displayed on the button
  @Input() iconPosition: 'left' | 'right' = 'left'; // Position of the icon relative to the text
  @Input() customClass: string = ''; // Any additional custom classes to apply to the button
  @Output() onClick = new EventEmitter<void>(); // Event emitter for click events

  /**
   * Handles the button click event and emits the onClick event.
   */
  handleClick() {
    if (!this.isDisabled) {
      this.onClick.emit(); // Emit the click event to the parent component
    }
  }
}
