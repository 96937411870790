import { Component, EventEmitter, forwardRef, Input, Output, TemplateRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'vw-text-input', // Selector for the text input component
  templateUrl: './text-input.component.html', // Template file for the text input component
  styleUrls: ['./text-input.component.scss'], // Corrected to 'styleUrls' for consistency with Angular documentation
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true
    }
  ]
})
export class TextInputComponent implements ControlValueAccessor {
  @Input() type: string = 'text'; // Input property to specify the type of input (e.g., text, password)
  @Input() placeholder: string = ''; // Input property for the placeholder text
  @Input() value: string = ''; // Input property for the current value of the input
  @Input() prefix!: TemplateRef<any>; // Input property for a template reference for a prefix (e.g., an icon or label)
  @Input() suffix!: TemplateRef<any>; // Input property for a template reference for a suffix (e.g., an icon or label)
  @Input() isDisabled: boolean = false; // Input property to control whether the input is disabled
  @Input() checked: boolean = false; // Input property for checkbox state
  @Input() isError: boolean = false; // Input property to indicate if there is an error state
  @Output() valueChange = new EventEmitter<string>(); // Event emitter for value changes
  @Output() checkedChange = new EventEmitter<boolean>(); // Event emitter for checkbox state changes
  onChange = (value: string) => {};
  onTouched = () => {};

  /**
   * Handles input events and emits the updated value.
   * @param event - The input event from the user.
   */
  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    this.value = input.value;
    this.onChange(this.value);
    this.onTouched();
    this.valueChange.emit(this.value);
  }

  /**
   * Handles checkbox change events and emits the new checked state.
   * @param event - The change event from the checkbox.
   */
  onCheckboxChange(event: Event) {
    const checkbox = event.target as HTMLInputElement; // Type assertion to access the checkbox state
    this.checkedChange.emit(checkbox.checked); // Emit the new checked state to the parent component
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
