<div class="relative inline-block w-full">
  <button (click)="toggleDropdown()" class="flex justify-between items-center w-full shadow px-3 py-3 text-base text-black rounded-xl outline-none bg-white relative">
    <span>{{ selectedValue || placeholder }}</span>
    <vw-icon-svg
      iconUrl="/assets/svg/down.svg"
      additionalClasses="w-3 h-[10px] transform transition-transform"
      [ngClass]="{ 'rotate-180': isOpen }">
    </vw-icon-svg>
  </button>

  <div *ngIf="isOpen" class="absolute left-0 right-0 mt-1 bg-white shadow px-3 py-3 text-base text-black rounded-xl outline-none z-[9999999]">
    <ul class="max-h-60 overflow-y-auto">
      <li *ngFor="let option of options; let i = index">
        <button (click)="selectOption(option)" class="block w-full text-left px-4 py-2 hover:bg-gray-100 focus:outline-none">
          {{ option.label }}
        </button>
      </li>
    </ul>
  </div>
</div>
