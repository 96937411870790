import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'vw-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent {
  @Input() isOpen: boolean = false; // Controls modal visibility
  @Input() width: string = '500px'; // Customizable width
  @Input() height: string = 'auto'; // Customizable height
  @Input() showCloseIcon: boolean = true; // Option to show or hide close icon
  @Input() closeIcon: string = 'fas fa-times'; // Customizable close icon class
  @Input() customClasses: string = ''; // Additional Tailwind CSS classes for styling

  @Output() close = new EventEmitter<void>(); // Emits event when modal is closed

  closeModal() {
    this.isOpen = false;
    this.close.emit(); // Emit close event
  }
}
