import { trigger, transition, style, animate, group, query, AnimationMetadata } from '@angular/animations';

// Fade In Animation
export const fadeIn = trigger('fadeIn', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('400ms ease-in', style({ opacity: 1 })),
  ]),
  transition(':leave', [
    animate('400ms ease-out', style({ opacity: 0 })),
  ]),
]);

// Slide In Animation
export const slideIn = trigger('slideIn', [
  transition(':enter', [
    style({ transform: 'translateX(-100%)', opacity: 0 }),
    animate('400ms ease-in', style({ transform: 'translateX(0)', opacity: 1 })),
  ]),
  transition(':leave', [
    animate('400ms ease-out', style({ transform: 'translateX(-100%)', opacity: 0 })),
  ]),
]);

// Zoom In Animation
export const zoomIn = trigger('zoomIn', [
  transition(':enter', [
    style({ transform: 'scale(0)', opacity: 0 }),
    animate('500ms ease-in', style({ transform: 'scale(1)', opacity: 1 })),
  ]),
  transition(':leave', [
    animate('500ms ease-out', style({ transform: 'scale(0)', opacity: 0 })),
  ]),
]);

// Bounce Animation
export const bounce = trigger('bounce', [
  transition(':enter', [
    style({ transform: 'translateY(100%)', opacity: 0 }),
    animate('600ms cubic-bezier(0.68, -0.55, 0.27, 1.55)', style({ transform: 'translateY(0)', opacity: 1 })),
  ]),
  transition(':leave', [
    animate('600ms cubic-bezier(0.68, -0.55, 0.27, 1.55)', style({ transform: 'translateY(100%)', opacity: 0 })),
  ]),
]);

// Rotate Animation
export const rotate = trigger('rotate', [
  transition(':enter', [
    style({ transform: 'rotate(0deg)', opacity: 0 }),
    animate('500ms ease-in', style({ transform: 'rotate(360deg)', opacity: 1 })),
  ]),
  transition(':leave', [
    animate('500ms ease-out', style({ transform: 'rotate(0deg)', opacity: 0 })),
  ]),
]);

// Fade and Slide Animation (Combination)
export const fadeAndSlide = trigger('fadeAndSlide', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(-20px)' }),
    animate('400ms ease-in', style({ opacity: 1, transform: 'translateY(0)' })),
  ]),
  transition(':leave', [
    animate('400ms ease-out', style({ opacity: 0, transform: 'translateY(-20px)' })),
  ]),
]);

// Flash Animation
export const flash = trigger('flash', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('200ms ease-in', style({ opacity: 1 })),
    animate('200ms ease-out', style({ opacity: 0 })),
    animate('200ms ease-in', style({ opacity: 1 })),
    animate('200ms ease-out', style({ opacity: 0 })),
  ]),
]);

// Fade Up Animation
export const fadeUp = trigger('fadeUp', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(20px)' }),
    animate('400ms ease-in', style({ opacity: 1, transform: 'translateY(0)' })),
  ]),
  transition(':leave', [
    animate('400ms ease-out', style({ opacity: 0, transform: 'translateY(20px)' })),
  ]),
]);

// Flip Animation
export const flip = trigger('flip', [
  transition(':enter', [
    style({ transform: 'rotateY(90deg)', opacity: 0 }),
    animate('600ms ease-in', style({ transform: 'rotateY(0deg)', opacity: 1 })),
  ]),
  transition(':leave', [
    animate('600ms ease-out', style({ transform: 'rotateY(90deg)', opacity: 0 })),
  ]),
]);

// Shake Animation
export const shake = trigger('shake', [
  transition(':enter', [
    style({ transform: 'translateX(0)' }),
    animate('0.5s', style({ transform: 'translateX(-10px)' })),
    animate('0.5s', style({ transform: 'translateX(10px)' })),
    animate('0.5s', style({ transform: 'translateX(0)' })),
  ]),
]);

// Roll Animation
export const roll = trigger('roll', [
  transition(':enter', [
    style({ transform: 'rotate(100deg)', opacity: 0 }),
    animate('600ms ease-in', style({ transform: 'rotate(0)', opacity: 1 })),
  ]),
  transition(':leave', [
    animate('600ms ease-out', style({ transform: 'rotate(100deg)', opacity: 0 })),
  ]),
]);

// Fade Left Animation
export const fadeLeft = trigger('fadeLeft', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateX(-20px)' }),
    animate('400ms ease-in', style({ opacity: 1, transform: 'translateX(0)' })),
  ]),
  transition(':leave', [
    animate('400ms ease-out', style({ opacity: 0, transform: 'translateX(-20px)' })),
  ]),
]);

// Fade Right Animation
export const fadeRight = trigger('fadeRight', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateX(20px)' }),
    animate('400ms ease-in', style({ opacity: 1, transform: 'translateX(0)' })),
  ]),
  transition(':leave', [
    animate('400ms ease-out', style({ opacity: 0, transform: 'translateX(20px)' })),
  ]),
]);

// Slide Down Animation
export const slideDown = trigger('slideDown', [
  transition(':enter', [
    style({ height: 0, opacity: 0 }),
    animate('400ms ease-in', style({ height: '*', opacity: 1 })),
  ]),
  transition(':leave', [
    animate('400ms ease-out', style({ height: 0, opacity: 0 })),
  ]),
]);

// Slide Up Animation
export const slideUp = trigger('slideUp', [
  transition(':enter', [
    style({ height: 0, opacity: 0 }),
    animate('400ms ease-in', style({ height: '*', opacity: 1 })),
  ]),
  transition(':leave', [
    animate('400ms ease-out', style({ height: 0, opacity: 0 })),
  ]),
]);

// Scale Animation
export const scale = trigger('scale', [
  transition(':enter', [
    style({ transform: 'scale(0)', opacity: 0 }),
    animate('500ms ease-in', style({ transform: 'scale(1)', opacity: 1 })),
  ]),
  transition(':leave', [
    animate('500ms ease-out', style({ transform: 'scale(0)', opacity: 0 })),
  ]),
]);

// Left Animation
export const left = trigger('routeAnimation', [
  transition(':increment', [
    query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
    group([
      query(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('0.3s ease-out', style({ transform: 'translateX(0%)' }))
      ], { optional: true }),
      query(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('0.3s ease-out', style({ transform: 'translateX(100%)' }))
      ], { optional: true }),
    ]),
  ]),
]);

// Right Animation
export const right = trigger('routeAnimation', [
  transition(':decrement', [
    query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
    group([
      query(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('0.3s ease-out', style({ transform: 'translateX(0%)' }))
      ], { optional: true }),
      query(':leave', [
        style({ transform: 'translateX(0%)' }),
        animate('0.3s ease-out', style({ transform: 'translateX(-100%)' }))
      ], { optional: true }),
    ]),
  ]),
]);

export const swipeCard = trigger('swipeCard', [
  // Animation for entering the view (slide in from the left)
  transition(':enter', [
    style({ opacity: 0 }),
    animate('400ms ease-in', style({ opacity: 1 })),
  ]),
  // Animation for leaving the view (slide out to the right)
  transition(':leave, * => out', [
    style({ transform: 'translateX(0)' }),
    animate('300ms ease', style({ transform: 'translateX(100%)' }))
  ])
]);


// // swipe Right Animation
// export const swipeRight = trigger('swipeRight', [
//   transition(':enter', [
//     style({ opacity: 0, transform: 'translateX(20px)' }),
//     animate('400ms ease-in', style({ opacity: 1, transform: 'translateX(0)' })),
//   ]),
//   transition(':leave', [
//     animate('400ms ease-out', style({ opacity: 0, transform: 'translateX(20px)' })),
//   ]),
// ]);
