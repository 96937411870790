import { Component, Input } from '@angular/core';

@Component({
  selector: 'vw-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() width: string = ''; // Default width
  @Input() height: string = ''; // Default height
  @Input() borderRadius: string = '0.5rem'; // Default border radius (rounded)
  @Input() customClasses: string = ''; // Additional custom Tailwind classes
}
