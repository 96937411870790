<button [attr.type]="type" [ngClass]="[color, textColor, customClass]"
  [disabled]="isDisabled"
  class="tracking-wide flex items-center justify-center px-4 py-[10px] rounded-full transition duration-200 ease-in-out focus:outline-none h-12  border-2 border-primary text-base font-medium "
  (click)="handleClick()">
  @if (icon && iconPosition === 'left') {
  <ng-container>
    <span class="mr-2 flex">
      <vw-icon-svg iconUrl={{icon}} additionalClasses="w-auto h-auto inline">
      </vw-icon-svg>
    </span>
  </ng-container>
  }
  {{ label }}
  @if (icon && iconPosition === 'right') {
  <ng-container>
    <span class="ml-2 flex">
      <vw-icon-svg iconUrl={{icon}} additionalClasses="w-auto h-auto inline">
      </vw-icon-svg>
    </span>
  </ng-container>
  }
</button>
