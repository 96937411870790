import { trigger, transition, style, animate } from '@angular/animations';
import { Component, Input, Renderer2 } from '@angular/core';

@Component({
  selector: 'vw-toast-popup',
  templateUrl: './toast-popup.component.html',
  styleUrl: './toast-popup.component.scss',
})
export class ToastPopupComponent {

  constructor(private renderer: Renderer2) {}


  /** Controls the visibility of the bottom sheet */
  @Input() isOpen = false;

  /** Height of the bottom sheet */
  @Input() height = 'auto';

  /** Width of the bottom sheet */
  @Input() width = '100%';

  /** Border radius of the bottom sheet */
  @Input() borderRadius = '0';

  /** Custom Tailwind CSS classes */
  @Input() customClass = '';


  ngOnChanges() {
    if (this.isOpen) {
      this.renderer.addClass(document.body, 'bottom-sheet-open');
    } else {
      this.renderer.removeClass(document.body, 'bottom-sheet-open');
    }
  }

  ngOnDestroy() {
    // Remove the class if the component is destroyed to avoid leaving it applied
    this.renderer.removeClass(document.body, 'bottom-sheet-open');
  }
}
