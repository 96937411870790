<div class="w-full">
    <div class="flex border border-[#63AE81] rounded-full p-[3px]">
      <div *ngFor="let tab of tabs; let i = index"
           (click)="selectTab(i)"
           [ngClass]="[
             tab.tailwindClasses,
             activeTab === i ? ' text-white bg-[#0F8A6D]' : 'text-[#0C0D0D]',
             tab.border
           ]"
           [ngStyle]="{ width: tab.width, height: tab.height }"
           class="cursor-pointer flex items-center justify-center py-[5.5px] px-3 text-center leading-normal">

        <i *ngIf="tab.icon" [class]="tab.icon"></i> <!-- Optional icon -->
        <span>{{ tab.label | translate: tab.labelPayload }}</span>
      </div>
    </div>

    <!-- Content projection for tab content -->
    <div class="mt-6">
      <ng-content></ng-content>
    </div>
  </div>
