import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'; // Import CommonModule
import { ButtonComponent } from './button/button.component';
import { TextInputComponent } from './text-input/text-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardComponent } from './card/card.component';
import { SliderComponent } from './slider/slider.component';
import { IconSvgComponent } from './icon-svg/icon-svg.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { TabComponent } from './tab/tab.component';
import { DialogComponent } from './dialog/dialog.component';
import { ToastPopupComponent } from './toast-popup/toast-popup.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
    ButtonComponent,
    TextInputComponent,
    CardComponent,
    SliderComponent,
    IconSvgComponent,
    DropdownComponent,
    CardComponent,
    TabComponent,
    DialogComponent,
    ToastPopupComponent

  ],
  imports: [
    CommonModule,
    FormsModule,
    NgxSliderModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
  exports: [
    ButtonComponent,
    TextInputComponent,
    SliderComponent,
    IconSvgComponent,
    DropdownComponent,
    CardComponent,
    TabComponent,
    DialogComponent,
    ToastPopupComponent
  ]
})
export class SharedModule { }
