import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'vw-slider', // Selector for the slider component
  templateUrl: './slider.component.html', // Template file for the slider component
  styleUrls: ['./slider.component.scss'], // Styles for the slider component
})
export class SliderComponent {
  @Input() isRange: boolean = true; // Determines if the slider is a range slider
  @Input() value: number = 50; // Current value for the slider (single value)
  @Input() minValue: number = 0; // Minimum value for the slider
  @Input() maxValue: number = 100; // Maximum value for the slider
  @Input() floor: number = 0; // Lower limit of the slider range
  @Input() ceil: number = 100; // Upper limit of the slider range
  @Input() sliderOptions: Options = {}; // Custom options for the slider
  @Input() customClasses: string = ''; // Additional CSS classes for the slider

  @Output() valueChange: EventEmitter<{ value?: number; minValue?: number; maxValue?: number }> = new EventEmitter(); // Event emitter for value changes

  options: Options = {
    floor: this.floor, // Sets the floor option for the slider
    ceil: this.ceil, // Sets the ceil option for the slider
    getSelectionBarColor: () => 'red', // Sets the color of the selection bar (customizable)
    translate: (): string => {
      return ''; // Custom translation function, can be modified as needed
    }
  };

  /**
   * Emits the current slider values when the slider changes.
   */
  onSliderChange(): void {
    if (this.isRange) {
      this.valueChange.emit({ minValue: this.minValue, maxValue: this.maxValue }); // Emit the min and max values for range sliders
    } else {
      this.valueChange.emit({ value: this.value }); // Emit the current value for single value sliders
    }
  }
}
