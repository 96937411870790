<div
  class="fixed inset-x-0 bottom-0 z-[999999] bg-white transition-transform transform translate-y-full"
  [ngClass]="{
    'translate-y-full': !isOpen,
    'translate-y-0': isOpen
  }"
  [ngStyle]="{
    height: height,
    width: width,
    borderRadius: borderRadius
  }"
  [ngClass]="customClass"
>
  <div class="bg-white rounded-3xl overflow-hidden h-full rounded-b-none ">
    <ng-content></ng-content> <!-- This allows you to pass any content inside the bottom sheet -->
  </div>
</div>
